import React from "react"
import { Label } from "reactstrap"
import { ClassesList } from "components"
import { sortNewClasses } from "util/sortUtil"

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table"

export const CurrentlySharedSolutions = ({
  sharedTeachers,
  onUnshareClick,
  onUnshareAllClick,
  onResendClick,
  editClick,
  allowWritableSharedSolutions,
}) => {
  const renderFullName = ({ row }) => {
    const teacher = row.original
    return `${teacher.firstName} ${teacher.lastName}`
  }

  const renderSharedClasses = React.useCallback(({ row }) => {
    const classes = sortNewClasses(row.original.sharedSolutionToken.newClasses)
    const rowId = row.id

    return <ClassesList classes={classes} rowId={rowId} />
  }, [])

  const renderViews = ({ row }) => {
    const optionIsSet = row.original.sharedSolutionToken

    const viewsObject = {
      "Assigned Teachers": optionIsSet.showTeachers,
      "Teacher Requests": optionIsSet.showTeacherRequests,
      "Student Requests": optionIsSet.showStudentRequests,
      "Friendship Nominations": optionIsSet.showFriendships,
      Characteristics: optionIsSet.showCharacteristics,
    }

    const text = Object.keys(viewsObject)
      .map(view => {
        return viewsObject[view] ? view : ""
      })
      .filter(str => str !== "")
      .join(", ")

    return text
  }

  const renderAbilities = ({ row }) => {
    const writable = row.original.sharedSolutionToken.writable
    const abilitiesObject = {
      "Move Students": writable,
    }

    const text = Object.keys(abilitiesObject)
      .map(view => {
        return abilitiesObject[view] ? view : ""
      })
      .filter(str => str !== "")
      .join(", ")

    return text === "" ? "View Only" : text
  }

  const renderAction = React.useCallback(
    ({ row }) => {
      const teacher = row.original
      return (
        <div className="d-flex">
          <div className="text-center pr-3">
            <span
              className="cursor-pointer color-blue-mid u-bold"
              onClick={() => editClick(teacher)}>
              Edit
            </span>
          </div>
          <div className="text-center pr-3">
            <span
              className="cursor-pointer color-blue-mid u-bold"
              onClick={() => onResendClick(teacher)}>
              Resend
            </span>
          </div>
          <div className="text-center">
            <span
              className="cursor-pointer color-blue-mid u-bold"
              onClick={() => onUnshareClick(teacher)}>
              Unshare
            </span>
          </div>
        </div>
      )
    },
    [editClick, onResendClick, onUnshareClick]
  )

  const columns = React.useMemo(
    () => [
      {
        header: "CURRENTLY SHARED WITH",
        accessorKey: "fullName",
        cell: renderFullName,
      },
      {
        header: "CLASSES SHARED",
        accessorKey: "classes",
        cell: renderSharedClasses,
      },
      {
        header: "ALLOW TEACHERS TO VIEW",
        accessorKey: "views",
        cell: renderViews,
      },
      {
        header: "ALLOW TEACHERS TO",
        accessorKey: "abilities",
        cell: renderAbilities,
      },
      {
        header: () => (
          <div style={{ display: "flex" }}>
            <Label className="py-3 c-teachers-invite-modal__text m-0">
              ACTIONS
            </Label>
            <div style={{ position: "absolute" }}>
              <div
                className="cursor-pointer color-blue-mid u-bold text-center shared-solution-table__unshare"
                onClick={onUnshareAllClick}>
                Unshare All
              </div>
            </div>
          </div>
        ),
        accessorKey: "action",
        cell: renderAction,
      },
    ],
    [renderAction, onUnshareAllClick, renderSharedClasses]
  )

  const data = React.useMemo(() => sharedTeachers, [sharedTeachers])

  const tableProps = {
    data: data,
    columns: columns,
    initialState: {
      hiddenColumns: allowWritableSharedSolutions ? [] : ["writable"],
    },
    getCoreRowModel: getCoreRowModel(),
  }

  const { getHeaderGroups, getRowModel } = useReactTable(tableProps)

  const getStylesForColumn = (columns, index, isHeader) => {
    const column = columns[index]
    const accessor = column.accessorKey

    if (accessor === "fullName") {
      return "shared-solution-table__fullName not-last-column"
    } else if (accessor === "classes") {
      return "shared-solution-table__classes shared-solution-table__not-initial-column shared-solution-table__not-last-column"
    } else if (accessor === "views") {
      return "shared-solution-table__views shared-solution-table__not-initial-column shared-solution-table__not-last-column"
    } else if (accessor === "abilities") {
      return `${isHeader ? "" : "shared-solution-table__abilities-row"} shared-solution-table__not-initial-column shared-solution-table__not-last-column`
    } else if (accessor === "action") {
      return "shared-solution-table__actions shared-solution-table__not-initial-column"
    } else {
      return ""
    }
  }

  return (
    <div className="pb-4">
      <div>
        {sharedTeachers.length === 0 ? (
          "Not currently shared with any teachers "
        ) : (
          <table className="shared-solution-table">
            <thead>
              {getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={`c-teachers-invite-modal__text ${getStylesForColumn(columns, index, true)}`}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {getRowModel().rows.map(row => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <td
                          key={cell.id}
                          className={`py-3 c-teachers-invite-modal__text ${getStylesForColumn(columns, index, false)}`}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
