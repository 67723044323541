import React, {
  useRef,
  forwardRef,
  useCallback,
  useMemo,
  useEffect,
} from "react"
import { ClassBadge } from "components"
import Select, { components } from "react-select"
import { fullName } from "util/nameUtil"

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table"

import { useVirtualizer } from "@tanstack/react-virtual"

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  )
})

export const FriendshipPreferencesTable = ({
  rowData,
  canonicalHeaders,
  nameMap,
  selectedRows,
  updateSelectedRows,
  updateNameMap,
  students,
}) => {
  const columns = useMemo(() => {
    const numFriends = canonicalHeaders.filter(
      header => header && header.startsWith("friend")
    ).length

    const friendColumns = [...Array(numFriends)].map((_, index) => {
      return {
        header: `Friend ${index + 1}`,
        cell: NameCell,
        accessorKey: `friend_${index + 1}`,
        meta: {
          className: "friendship-column",
          cellClassName: "friend-cell",
        },
      }
    })

    return [
      {
        id: "selection",
        header: ({ table }) => {
          return (
            <div className="d-flex flex-column align-items-center">
              <IndeterminateCheckbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            </div>
          )
        },
        cell: ({ row }) => {
          return (
            <div className="d-flex flex-column align-items-center">
              <IndeterminateCheckbox
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                onChange={row.getToggleSelectedHandler()}
              />
            </div>
          )
        },
      },
      {
        header: "Student",
        cell: NameCell,
        accessorKey: "student",
      },
      ...friendColumns,
    ]
  }, [canonicalHeaders])

  const data = useMemo(() => rowData, [rowData])

  // Set up the Tanstack table
  const table = useReactTable({
    columns,
    data,
    state: {
      rowSelection: convertToRowSelection(selectedRows),
    },
    meta: {
      nameMap,
      studentOptions: studentOptions(students),
      updateNameMap,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection => {
      const prevRowSelection = convertToRowSelection(selectedRows)
      const newRowSelection = setRowSelection(prevRowSelection)

      updateSelectedRows(convertFromRowSelection(newRowSelection))
    },
    autoResetSelectedRows: false,
    getCoreRowModel: getCoreRowModel(),
  })

  const { rows } = table.getRowModel()

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    // Wrap all the functions in `useCallback` in an attempt to
    // not hit the maximum React render depth
    estimateSize: useCallback(() => 33, []), //estimate row height for accurate scrollbar dragging
    getScrollElement: useCallback(() => tableContainerRef.current, []),
    measureElement: useCallback(
      element => Math.round(element.getBoundingClientRect().height),
      []
    ),
    overscan: 20,
  })

  // For each column in the data, find the widest cell according
  // to the `widthEstimation` function in the meta for the column.
  //
  // The `widthEstimation` function will take the cell value from `getValue()`
  // and return a width estimate for the cell. This will then be used to determine
  // which cell is the widest.
  //
  // NOTE: Ideally we should give the props from cell.getContext() to the function
  // because it contains more information and is what is given to the `cell` during
  // render. However, accessing it is much much slower because the cell value is cached
  // (0ms compared to 0.01ms) and we access it a lot so it causes a big delay
  // for tables with a lot of cells.
  //
  // This is not going to be 100% accurate but it should cover most schools
  const visibleColumns = table.getVisibleLeafColumns()

  const widestCells = useMemo(() => {
    // By default we just use the length of the value
    const defaultWidthEstimation = cellValue => {
      return `${cellValue ? cellValue : ""}`.length
    }

    return visibleColumns.map(column => {
      if (rows.length === 0) return null
      const colIndex = column.getIndex()
      const widthEstimation =
        column.columnDef?.meta?.widthEstimation ?? defaultWidthEstimation

      const rowWithWidestCell = rows.reduce((acc, row) => {
        const rowCellLength = widthEstimation(row.getValue(column.id))
        const accCellLength = widthEstimation(acc.getValue(column.id))

        if (rowCellLength > accCellLength) {
          return row
        } else {
          return acc
        }
      })

      return rowWithWidestCell.getVisibleCells()[colIndex]
    })
  }, [rows, visibleColumns])

  const virtualItems = rowVirtualizer.getVirtualItems()

  // Position what rows to show by putting top and bottom padding rows
  // to push the rows to the correct position. We don't want to
  // use `position: absolute` because it doesn't work properly with
  // table rows. See https://github.com/TanStack/virtual/discussions/476#discussioncomment-4724139
  const [paddingTop, paddingBottom] =
    virtualItems.length > 0
      ? [
          Math.max(
            0,
            virtualItems[0].start - rowVirtualizer.options.scrollMargin
          ),
          Math.max(
            0,
            rowVirtualizer.getTotalSize() -
              virtualItems[virtualItems.length - 1].end
          ),
        ]
      : [0, 0]

  return (
    <div
      className="friendship-preferences-import-table"
      ref={tableContainerRef}>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    className={header.column.columnDef?.meta?.className}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
          }}>
          {/* Add padding row to position the rows into place */}
          {paddingTop > 0 && (
            <tr>
              <td colSpan={visibleColumns.length} style={{ paddingTop }} />
            </tr>
          )}

          {/* Add a collapsed row which contains the widest cells per column in the data to avoid
              the column width from shifting while scrolling */}
          <tr style={{ visibility: "collapse" }}>
            {widestCells.map(cell => {
              return (
                <td
                  key={`padding-${cell.id}`}
                  className={cell.column.columnDef?.meta?.cellClassName}>
                  >{flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )
            })}
          </tr>

          {virtualItems.map(virtualRow => {
            const row = rows[virtualRow.index]

            return (
              <tr
                key={row.id}
                data-index={virtualRow.index}
                ref={rowVirtualizer.measureElement}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className={`${cell.column.columnDef?.meta?.cellClassName || ""} ${virtualRow.index % 2 === 0 ? "friendship-preferences-import-table__even" : "friendship-preferences-import-table__odd"}`}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          {paddingBottom > 0 && (
            <tr>
              <td colSpan={visibleColumns.length} style={{ paddingBottom }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const studentOptions = students => {
  return [
    // Ignored option
    {
      value: "",
      label: "--- IGNORED ---",
    },
    ...students.map(student => {
      const label = student.currentClass
        ? `${fullName(student)} (${student.currentClass.label})`
        : `${fullName(student)}`

      return {
        value: student.id,
        label: label,
      }
    }),
  ]
}

const selectMenu = name => {
  return props => {
    return (
      <>
        <components.MenuList {...props}>{props.children}</components.MenuList>
        <div className="pl-2 py-2 border-top font-size-14 text-danger">
          {name}
        </div>
      </>
    )
  }
}

const NameCell = props => {
  const {
    getValue,
    row: { toggleSelected },
    column: { id: columnId },
    table: {
      options: {
        meta: { nameMap, studentOptions, updateNameMap },
      },
    },
  } = props

  const name = getValue()

  const student = nameMap[name]
  // There are 4 states that a name can be in:
  //
  // mapped - string in file has been mapped to a student id
  // ignored - string in file has been mapped to "", which means no student
  // unmapped - string in file has not been mapped yet
  // empty - string in file is ""
  const isEmpty = name === ""
  const isMapped = !isEmpty && name in nameMap && student
  const isIgnored = !isEmpty && name in nameMap && !student
  const isUnmapped = !isEmpty && !(name in nameMap)
  const isStudentColumn = columnId === "student"

  const isSelected = props.row.getIsSelected()
  // Do not disable the student column
  const isDisabled = isStudentColumn
    ? !isIgnored && !isSelected
    : !isSelected || isEmpty

  // If the student column is ignored, then ignore the entire row
  if (isIgnored && isSelected && isStudentColumn) {
    toggleSelected()
  }

  let selectedValue
  if (isMapped) {
    selectedValue = {
      value: student.id,
      label: (
        <div className="d-flex justify-content-between align-items-center">
          <div className="mr-3">{fullName(student)}</div>
          {student.currentClass && (
            <ClassBadge
              label={student.currentClass.label}
              className={`${isDisabled && "c-class-badge--disabled"}`}
            />
          )}
        </div>
      ),
    }
  } else if (isIgnored) {
    selectedValue = {
      value: "",
      label: <div className="text-center align-center">--- IGNORED ---</div>,
    }
  } else if (isUnmapped) {
    selectedValue = {
      value: "",
      label: (
        <div className="d-flex justify-content-between align-items-center">
          {name}
          {!isDisabled && <i className="ml-3 fa fa-warning text-danger" />}
        </div>
      ),
    }
  } else if (isEmpty) {
    selectedValue = {
      value: "",
      label: null,
    }
  }

  const danger = isUnmapped && !isDisabled ? "border border-danger" : ""

  return (
    <>
      <Select
        name="nameMapping"
        className={`${danger}`}
        options={studentOptions}
        value={selectedValue}
        onChange={({ value: studentId }) => updateNameMap(name, studentId)}
        components={{
          MenuList: selectMenu(name),
          IndicatorSeparator: null,
          DropdownIndicator: null,
        }}
        isDisabled={isDisabled}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            backgroundColor: null,
            borderStyle: null,
          }),
        }}
      />
    </>
  )
}

// Convert to React Tables `rowSelection`, e.g.
// [0,3] => {0: true, 3: true}
const convertToRowSelection = rowIndices =>
  Object.fromEntries(rowIndices.map(rowIndex => [rowIndex, true]))

// Convert from React Tables `rowSelection`, e.g.
// {0: true, 3: true} => [0,3]
const convertFromRowSelection = rowSelection =>
  Object.keys(rowSelection).map(rowIndex => parseInt(rowIndex))
