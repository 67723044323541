import React from "react"
import { InlineError, AddButton } from "components"
import { isDefinedNotNull, getPropertyIfDefined } from "util/objUtil"
import { fullName, userInitials } from "util/nameUtil"
import { dateToString } from "util/dateTimeUtil"

import moment from "moment"

const UserActivityText = ({ text, colourClass }) => {
  const className = `color-grey-mid-light rounded inline-block ${colourClass}`
  return (
    <p
      className={className}
      style={{
        fontSize: "0.7rem",
        padding: "0px 6px",
        float: "left",
        margin: "0",
      }}>
      {text}
    </p>
  )
}

const UserActivityStatus = ({
  lastLogin,
  insertedAt,
  updatedAt,
  emailVerified,
  disabled,
}) => {
  let text = "Unknown"
  let colourClass

  if (disabled) {
    text = `Deactivated ${formatDate(updatedAt)}`
    colourClass = "color-admin_deactivated"
  } else if (emailVerified) {
    const now = moment()
    const duration = moment.duration(now.diff(moment(lastLogin)))
    const differenceInMonths = duration.asMonths()

    if (!lastLogin) {
      text = `Email Verified: ${formatDate(updatedAt)}`
      colourClass = "color-admin_last_active_first"
    } else if (differenceInMonths < 12) {
      text = `Last active ${formatDate(lastLogin)}`
      colourClass = "color-admin_last_active_first"
    } else if (differenceInMonths < 18) {
      text = `Last active ${formatDate(lastLogin)}`
      colourClass = "color-admin_last_active_second"
    } else {
      text = `Inactive since ${formatDate(lastLogin)}`
      colourClass = "color-admin_last_active_third"
    }
  } else {
    text = `Email not verified: added ${formatDate(insertedAt)}`
    colourClass = "color-admin_not_activated"
  }

  return <UserActivityText text={text} colourClass={colourClass} />
}

const formatDate = timestamp => dateToString(timestamp)

export const AdminList = ({
  users,
  onEditAdminClick,
  onAddAdminClick,
  errors,
  allowAdd = true,
  allowEdit = true,
  invitations = [],
  onRevokeInvitationClick,
  allowRemove = false,
  onRemoveAdminClick,
}) => (
  <div className="c-admin">
    <ul className="c-admin__list-container">
      {users.map((user, i) => {
        const {
          email,
          positionTitle,
          disabled,
          lastLogin,
          insertedAt,
          updatedAt,
          emailVerified,
        } = user

        return (
          <li className="c-admin__list-item" key={i}>
            <div className="c-admin__sub-container">
              <div className="c-admin__icon">{userInitials(user)}</div>
              <div>
                <div>{`${fullName(user)}${
                  isDefinedNotNull(positionTitle) ? `, ${positionTitle}` : ""
                }`}</div>
                <div>{email}</div>
                <UserActivityStatus
                  lastLogin={lastLogin}
                  insertedAt={insertedAt}
                  updatedAt={updatedAt}
                  emailVerified={emailVerified}
                  disabled={disabled}
                />
              </div>
            </div>

            {allowEdit && (
              <a
                className="text-primary"
                onClick={() => onEditAdminClick(user)}>
                Edit
              </a>
            )}
            {allowRemove && (
              <a
                className="text-primary"
                onClick={() => onRemoveAdminClick(user)}>
                Remove
              </a>
            )}
          </li>
        )
      })}
      {invitations.map((invitation, i) => {
        return (
          <li className="c-admin__list-item" key={i}>
            <div className="c-admin__sub-container">
              <div className="c-admin__icon">{userInitials(invitation)}</div>
              <div>
                <div className="color-grey-mid">Details not yet provided</div>
                <div>{invitation.email}</div>
                <UserActivityText
                  text={`Invitation sent on: ${formatDate(invitation.updatedAt)}`}
                  colourClass="color-admin_last_active_third"
                />
              </div>
            </div>
            <a
              className="text-primary"
              onClick={() => onRevokeInvitationClick(invitation)}>
              Remove
            </a>
          </li>
        )
      })}
    </ul>
    <InlineError text={getPropertyIfDefined(errors, "activate")} />
    <AddButton
      active={allowAdd}
      text="Add new administrator"
      onClick={onAddAdminClick}
    />
  </div>
)
