import React from "react"
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table"
import { needHighlighting } from "util/teacherUtil"
import { dateToString } from "util/dateTimeUtil"

export const TeachersTable = props => {
  // Destructure the props we want
  const {
    teachers,
    onRowClick,
    closeSurveysButton,
    onSendMultipleSurveysClick,
    adminEmailDomain,
    surveysSent,
  } = props

  // Define and memoize the table's columns
  const columns = React.useMemo(() => {
    return [
      {
        header: "Teacher Details",
        columns: [
          {
            header: "First Name",
            accessorKey: "firstName",
            meta: {
              cellClassName: "first-name-cell",
            },
          },
          {
            header: "Last Name",
            accessorKey: "lastName",
            meta: {
              cellClassName: "last-name-cell",
            },
          },
          {
            header: "Email",
            accessorKey: "email",
            cell: EmailCell,
            meta: {
              cellClassName: "email-cell wide-cell",
              adminEmailDomain: adminEmailDomain,
            },
          },
        ],
      },
      {
        header: "CURRENT CLASS OR CLASSES",
        meta: {
          className: "first-in-group-header text-nowrap current-class-heading",
        },
        columns: [
          {
            header: "Current School Year",
            accessorKey: "allClasses",
            id: "allClasses",
            cell: ({ cell }) => cell.getValue(),
            meta: {
              cellClassName:
                "current-class-cell class-1-cell first-in-group-cell",
              className: "first-in-group-header classes-header",
            },
          },
        ],
      },
      {
        header: "Survey",
        meta: {
          className: "first-in-group-header text-nowrap survey-class-heading",
        },
        columns: [
          {
            header: "Allocated to Class",
            accessorKey: "allocated",
            cell: ({ cell }) => cell.getValue(),
            enableSorting: false,
            meta: {
              cellClassName:
                "survey-class-cell class-1-cell first-in-group-cell passive-cell",
              className: "first-in-group-header passive-cell ",
            },
          },
        ],
      },
      {
        id: "send-multiple-new-surveys",
        header: () => (
          <span onClick={onSendMultipleSurveysClick}>
            Send Multiple New Surveys
          </span>
        ),
        meta: {
          className: "button-header less-wide-cell",
        },
        columns: [
          {
            header: "Status",
            accessorKey: "status",
            cell: SurveyStatusCell,
            enableSorting: false,
            meta: {
              cellClassName: "less-wide-cell passive-cell",
              className: "passive-cell",
            },
          },
        ],
      },
      {
        id: "close-all-surveys",
        header: () => closeSurveysButton,
        meta: {
          className: "button-header close-header",
        },
        columns: [
          {
            header: "",
            accessorKey: "resend",
            cell: ({ cell }) => cell.getValue(),
            enableSorting: false,
            meta: {
              cellClassName: "passive-cell button-cell end-cell",
              className: "passive-cell",
              disableSortBy: true,
            },
          },
        ],
      },
    ]
  }, [adminEmailDomain, closeSurveysButton, onSendMultipleSurveysClick])

  // Memoize the data to render
  const teacherData = React.useMemo(() => teachers, [teachers])

  // Set up the table's props
  const tableProps = {
    data: teacherData,
    columns: columns,
    state: {
      // If surveys has not been sent, we need to hide the survey columns
      columnVisibility:
        surveysSent === true
          ? {}
          : {
              status: false,
              allocated: false,
              resend: false,
            },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  }

  // Bootstrap the table
  const { getHeaderGroups, getRowModel } = useReactTable(tableProps)

  // Render the table!
  return (
    <div className="teacher-sortable-table">
      <table cellSpacing="0">
        <thead className={teachers.length === 0 ? "no-data" : ""}>
          {getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                // Render the 'th' cell as a sortable column
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`${header.column.columnDef?.meta?.className || ""} ${
                      header.column.getCanSort()
                        ? "cursor-pointer select-none"
                        : ""
                    }`}
                    onClick={header.column.getToggleSortingHandler()}
                    title={
                      header.column.getCanSort()
                        ? header.column.getNextSortingOrder() === "asc"
                          ? "Sort ascending"
                          : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                        : undefined
                    }>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {/* Add a sort direction indicator */}
                    {(header.column.columnDef?.meta?.showDirectionIndicator ??
                    true) ? (
                      <DirectionIndicator
                        isSorted={header.column.getIsSorted()}
                      />
                    ) : null}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        {teachers.length === 0 && (
          <tbody>
            <tr className="empty-row">
              <td className="empty-data-cell" colspan="100">
                There are no teachers to view. Try adding a new one.
              </td>
            </tr>
          </tbody>
        )}

        {teachers.length > 0 && (
          <tbody>
            {getRowModel().rows.map(row => {
              // Handle which this teacher is clicked
              const handleClick = () => onRowClick(teacherData[row.index])

              return (
                <tr key={row.id} onClick={handleClick}>
                  {row.getVisibleCells().map(cell => {
                    // Render the cell
                    return (
                      <td
                        key={cell.id}
                        className={cell.column.columnDef?.meta?.cellClassName}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
    </div>
  )
}

const DirectionIndicator = ({ isSorted }) => {
  return (
    <span>
      {{
        asc: " ↑",
        desc: " ↓",
      }[isSorted] ?? null}
    </span>
  )
}

const EmailCell = props => {
  const email = props.getValue()
  // Determine if this email needs to be highlighted
  const needsHighlighting = needHighlighting(
    email,
    props.column.columnDef.meta.adminEmailDomain
  )

  // Render the cell
  return <span className={`highlight-${needsHighlighting}`}>{email}</span>
}

const SurveyStatusCell = props => {
  // Retrieve the teacher from the table's data set
  const teacher = props.row.original

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <div>{props.getValue()}</div>
      {teacher.survey && (
        <span className="font-weight-light pr-1">
          {dateToString(teacher.survey.updatedAt)}
        </span>
      )}
    </div>
  )
}
